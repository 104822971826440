<template>
    <div>
        
        <loading-flux :value="loading"></loading-flux>
        
        <div class="card-title mb-4">
            Solicitud
        </div>
        <div class="table-responsive" id="tabla-productos-anteriores">
            <table class="table mb-0">
                <tbody>
                    <tr>
                        <td>Comentario Solicitud :</td>
                        <td>
                            {{ solicitudMaterial.solicitud_comentario }}
                        </td>
                    </tr>
                    <tr>
                        <td>Comentario Vale Consumo :</td>
                        <td>
                            {{ solicitudMaterial.vale_consumo_comentario }}
                        </td>
                    </tr>
                    <tr>
                        <td>Comentario Devolución</td>
                        <td>{{ solicitudMaterial.devolucion_comentario }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-title mt-4 mb-4">
            Productos
        </div>
        <div
            class="table-responsive"
            id="tabla-productos-anteriores"
            style="overflow-x: auto;height: 400px;"
        >
            <table class="table mb-0">
                <tbody>
                    <template
                        v-for="(producto, index) in solicitudMaterialDetalles"
                    >
                        <tr :key="'a' + index">
                            <th>Código :</th>
                            <td>
                                <span class="badge font-size-13 bg-primary">
                                    {{ producto.codigo_producto }}
                                </span>
                            </td>
                        </tr>
                        <tr :key="'b' + index">
                            <td>Nombre :</td>
                            <td>{{ producto.nombre_producto }}</td>
                        </tr>
                        <tr :key="'c' + index">
                            <td>Comentario Producto :</td>
                            <td>
                                {{ producto.comentario }}
                            </td>
                        </tr>
                        <tr :key="'d' + index">
                            <td>Comentario Recepcionado :</td>
                            <td>
                                {{ producto.comentario_recepcionada }}
                            </td>
                        </tr>
                        <tr :key="'e' + index">
                            <td>Comentario Consumido :</td>
                            <td>
                                {{ producto.comentario_consumida }}
                            </td>
                        </tr>
                        <tr :key="'f' + index">
                            <td>Comentario Devuelto :</td>
                            <td>
                                {{ producto.comentario_devuelta }}
                            </td>
                        </tr>
                        <tr :key="'g' + index">
                            <td style="padding: 19px;"></td>
                            <td style="padding: 19px;"></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <!-- <div class="text-end mt-3">
            <b-button variant="success" @click="cerrarDetalle">Cerrar</b-button>
        </div> -->
    </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { productoMethods, productoComputed } from "@/state/helpers";
import {
    solicitudMaterialMethods,
    solicitudMaterialComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { bodegaMethods, bodegaComputed } from "@/state/helpers";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    props: {
        solicitudMaterial: Object,
        lugarInstalacion: Object,
    },
    data() {
        return {
            loading: false,
            proyectos: [],
            codigoProyecto: null,
            codigoLugarInstalacion: null,
            solicitudMaterialDetalles: [],
            solicitudMaterialDetallesCargando: false,
            rolModuloPermiso: {},
        };
    },
    mounted() {
        this.obtenerSolicitudMaterialDetallesProyecto();
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...solicitudMaterialMethods,
        ...productoMethods,
        ...proyectoMethods,
        ...bodegaMethods,
        cerrarDetalle() {
            this.$emit("cerrarDetalleERP");
        },
        obtenerSolicitudMaterialDetallesProyecto() {
            this.solicitudMaterialDetalles = [];
            this.solicitudMaterialDetallesCargando = true;
            this.obtenerSolicitudMaterialDetalles(
                this.solicitudMaterial.solicitud_material_id
            )
                .then((res) => {
                    this.solicitudMaterialDetalles = res.body;
                    this.solicitudMaterialDetallesCargando = false;
                })
                .catch((error) => {
                    this.solicitudMaterialDetallesCargando = false;
                });
        },
        colorTipoSolicitud(tipoSolicitud) {
            if (tipoSolicitud == "Solicitud") {
                return "bg-success";
            } else if (tipoSolicitud == "Reserva") {
                return "bg-primary";
            } else if (tipoSolicitud == "Recepcion") {
                return "bg-warning";
            } else if (tipoSolicitud == "Consumo") {
                return "bg-info";
            } else if (tipoSolicitud == "ValeConsumo") {
                return "bg-info";
            } else if (tipoSolicitud == "Devolucion") {
                return "bg-danger";
            }
        },
        irLugarinstalacion(proyecto) {
            this.seleccionarProyecto(proyecto[0]);
            this.$router.push({
                name: "lugaresInstalacion",
                params: { codigoProyecto: proyecto[0].codigo_proyecto },
            });
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>

<style>
.crearActividadesProyecto {
    margin-right: 10px;
}

.table-solicitud-material-detalle {
    height: 300px;
}

.modal-producto-detalle-contenedor {
    padding: 20px;
    /* font-size: 0.9rem; */
}

.modal-producto-detalle-descripcion {
    font-weight: bold;
}

.modal-producto-detalle-fila {
    padding: 8px 0;
}
.modal-producto-detalle-titulo {
    padding: 0 20px;
    font-size: 1.2rem;
}
.modal-producto-detalle-subtitulo {
    padding: 0 20px;
    margin: 0;
}

.comentarios-solicitud {
    margin-bottom: 20px;
}
.comentarios-solicitud-titulo {
    font-weight: bold;
}
</style>
