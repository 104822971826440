<template>
  <v-container>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- height: 770px; -->
          <div class="card-body" style="height: 900px;">
            <b-alert
              :show="segundosMensajeActualizacion"
              dismissible
              :variant="mensaje.variant"
              @dismissed="segundosMensajeActualizacion = 0"
              @dismiss-count-down="actualizarSegundosMensajeActualizacion"
            >
              {{ mensaje.texto }}
            </b-alert>
            <div class="">
              <div class="row ">
                <div class="card-title col-auto me-auto">
                  Solicitud Materiales
                </div>
                <div
                  class="boton-solicitud-material col-lg-auto col-md-12 align-self-end"
                >
                  <b-dropdown
                    id="filtrarSolicitudes"
                    text="Filtrar"
                    class="me-2 w-lg"
                    variant="primary"
                  >
                    <b-overlay :show="filtrando" variant="white" rounded="sm">
                      <template #button-content>
                        <i class="bx bx-slider me-1"></i>
                        Filtrar
                      </template>
                      <div class="dropdown-item-personalizado">
                        <b-form-group
                          label="Rechazada"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-form-radio-group
                            v-model="selectedRechazada"
                            :options="optionsRechazada"
                            :aria-describedby="ariaDescribedby"
                            name="rechazada"
                            button-variant="outline-primary"
                            buttons
                            @change="filtrar"
                          ></b-form-radio-group>
                          <!-- <i
                                                class="limpiar fas fa-broom"
                                                @click="limpiarRechazada"
                                            ></i> -->
                        </b-form-group>
                      </div>

                      <div class="dropdown-item-personalizado">
                        <b-form-group
                          label="Recepcionada"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-form-radio-group
                            v-model="selectedRecepcionada"
                            :options="optionsRecepcionada"
                            :aria-describedby="ariaDescribedby"
                            name="recepcionada"
                            button-variant="outline-primary"
                            buttons
                            @change="filtrar"
                          ></b-form-radio-group>
                          <!-- <i class="limpiar fas fa-broom"></i> -->
                        </b-form-group>
                      </div>

                      <div class="dropdown-item-personalizado">
                        <b-form-group
                          label="Vale Consumo"
                          v-slot="{ ariaDescribedby }"
                        >
                          <b-form-radio-group
                            v-model="selectedValeConsumo"
                            :options="optionsValeConsumo"
                            :aria-describedby="ariaDescribedby"
                            name="valeConsumo"
                            button-variant="outline-primary"
                            buttons
                            @change="filtrar"
                          ></b-form-radio-group>
                          <!-- <i class="limpiar fas fa-broom"></i> -->
                        </b-form-group>
                      </div>

                      <div class="dropdown-item-personalizado">
                        <label for="estadoSolicitud">Estado Solicitud</label>
                        <multiselect
                          v-model="selectedEstadoSolicitud"
                          :options="estadosSolicitud"
                          placeholder="Seleccionar"
                          value="codigo"
                          label="nombre"
                          :searchable="true"
                          class="helo"
                          @input="filtrar"
                        ></multiselect>
                        <!-- <i class="limpiar fas fa-broom"></i> -->
                      </div>
                      <div class="dropdown-item-personalizado">
                        <label for="tipoBodega">Tipo Bodega</label>
                        <multiselect
                          v-model="selectedTipoBodega"
                          :options="tiposBodega"
                          placeholder="Seleccionar"
                          value="codigo"
                          label="nombre"
                          :searchable="true"
                          class="helo"
                          @input="filtrar"
                        ></multiselect>
                        <!-- <i class="limpiar fas fa-broom"></i> -->
                      </div>
                      <div class="dropdown-item-personalizado">
                        <label for="fecha">Fecha Creación</label>

                        <b-form-input
                          v-model="selectedFechaCreacion"
                          id="selectedFechaCreacion"
                          type="date"
                          placeholder="Seleccionar"
                          @change="filtrar"
                        ></b-form-input>
                        <!-- <i class="limpiar fas fa-broom"></i> -->

                        <!-- <b-form-datepicker
                                            v-model="selectedFechaCreacion"
                                            placeholder="Seleccionar"
                                            class="mb-2"
                                            :state="fechaValidar"
                                            @input="fecha"
                                        ></b-form-datepicker> -->
                      </div>
                      <div class="dropdown-item-personalizado">
                        <label for="fecha">Fecha Entrega</label>
                        <!-- <b-form-datepicker
                                            v-model="selectedFechaEntrega"
                                            placeholder="Selecciona"
                                            class="mb-2"
                                        ></b-form-datepicker> -->

                        <b-form-input
                          v-model="selectedFechaEntrega"
                          id="selectedFechaEntrega"
                          type="date"
                          placeholder="Seleccionar"
                          @change="filtrar"
                        ></b-form-input>
                        <!-- <i class="limpiar fas fa-broom"></i> -->
                      </div>
                      <div class="dropdown-item-personalizado">
                        <button
                          class="col-12 btn btn-primary"
                          @click="limpiarFiltro()"
                        >
                          <i class="fas fa-broom me-1"></i>
                          Limpiar
                        </button>
                      </div>
                    </b-overlay>
                  </b-dropdown>
                </div>
                <div
                  class="boton-solicitud-material col-lg-auto col-md-12 mt-md-10 align-self-end"
                >
                  <button class="btn btn-primary w-lg" @click="exportarExcel()">
                    <i class="bx bx-download me-1"></i>
                    Exportar
                  </button>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select
                      class="form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Buscar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="datatables tabla-personalizada"
                :items="solicitudesFiltrados"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                select-mode="single"
                selectable
                @filtered="onFiltered"
              >
                <template #cell(accion)="data">
                  <i
                    class="bx bx-detail text-primary icono-botom-tabla"
                    @click="abrirModalDetalle(data.item)"
                    v-b-tooltip.focus
                    title="Ver productos de la solicitud"
                  ></i>
                  <!-- <i
                                        class="bx bx-list-ul text-primary icono-botom-tabla"
                                        @click="abrirModalDetalleERP(data.item)"
                                        v-b-tooltip.focus
                                        title="Ver comentarios de la solicitud y de los productos"
                                    ></i> -->
                  <i
                    v-if="data.item.tipo_bodega == 'Central'"
                    class="mdi mdi-truck-fast-outline text-primary icono-botom-tabla"
                    @click="abrirModalSolicitudDespacho(data.item)"
                    v-b-tooltip.focus
                    title="Ver las solicitudes de despacho"
                  ></i>
                </template>
                <template #cell(tipo_solicitud)="data">
                  <span
                    class="badge font-size-13"
                    :class="colorTipoSolicitud(data.item.tipo_solicitud)"
                  >
                    {{ data.item.tipo_solicitud }}
                  </span>
                </template>

                <template #cell(rechazada)="data">
                  <span class="">
                    {{ data.item.rechazada == 0 ? "No" : "Si" }}
                  </span>
                </template>
                <template #cell(recepcionada)="data">
                  <span class="">
                    {{ data.item.recepcionada == 0 ? "No" : "Si" }}
                  </span>
                </template>
                <template #cell(vale_consumo)="data">
                  <span class="">
                    {{ data.item.vale_consumo == 0 ? "No" : "Si" }}
                  </span>
                </template>
                <template #cell(nombre_estado_solicitud)="data">
                  <span
                    class="badge font-size-13"
                    :class="
                      colorTipoSolicitud(data.item.nombre_estado_solicitud)
                    "
                  >
                    {{ data.item.nombre_estado_solicitud }}
                  </span>
                </template>
                <template #cell(tipo_bodega)="data">
                  <span
                    class="badge font-size-13"
                    :class="colorTipoSolicitud(data.item.tipo_bodega)"
                  >
                    {{ data.item.tipo_bodega }}
                  </span>
                </template>
                <template #cell(nombre_usuario)="data">
                  <span class="font-size-13">
                    {{ data.item.nombre_usuario }} ({{ data.item.email }})
                  </span>
                </template>
                <template #cell(fecha_creacion)="data">
                  {{ formatDateTime(data.item.fecha_creacion) }}
                </template>
                <template #cell(fecha_entrega)="data">
                  {{ formatDate(data.item.fecha_entrega) }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="mostrarModal"
      title="Agregar Solicitud"
      hide-footer
      size="xl"
    >
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="producto">Producto</label>
              <multiselect
                v-model="$v.form.producto.$model"
                :options="productos"
                placeholder="Seleccionar Producto"
                value="Producto"
                label="Descripción"
                :custom-label="descripcionProducto"
                :searchable="true"
                :loading="productosCargando"
                open-direction="bottom"
                :disabled="esEditar"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.producto.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.producto.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="cantidad">Cantidad</label>
              <input
                id="cantidad"
                v-model="$v.form.cantidad.$model"
                type="text"
                class="form-control"
                placeholder="Cantidad"
                :class="{
                  'is-invalid': submitted && $v.form.cantidad.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.cantidad.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="fecha">Fecha</label>
              <b-form-datepicker
                v-model="$v.form.fecha.$model"
                placeholder="Fecha"
                :state="fechaValidar"
                class="mb-2"
              ></b-form-datepicker>

              <div
                v-if="submitted && !$v.form.fecha.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="comentario">Comentario</label>
              <input
                id="comentario"
                v-model="$v.form.comentario.$model"
                type="text"
                class="form-control"
                placeholder="Comentario"
                :class="{
                  'is-invalid': submitted && $v.form.comentario.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.comentario.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="unidad">Unidad</label>
              <multiselect
                v-model="$v.form.unidad.$model"
                :options="unidades"
                placeholder="Seleccionar Unidad"
                value="value"
                label="label"
                :searchable="true"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.unidad.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.unidad.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="table-responsive table-solicitud-material-detalle">
            <table class="table mb-0 tabla-personalizada">
              <thead>
                <tr>
                  <th>Eliminar</th>
                  <th>Codigo Producto</th>
                  <th>Nombre Producto</th>
                  <th>Cantidad</th>
                  <th>Unidad</th>
                  <th>Fecha</th>
                  <th>Comentario</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(producto, i) in solicitudMaterial.productos"
                  :key="i"
                >
                  <td>
                    <i
                      v-if="rolModuloPermiso.escritura"
                      class="bx bx-trash text-primary icono-botom-tabla"
                      @click="eliminarProducto(producto)"
                    ></i>
                  </td>
                  <td>{{ producto.codigo_producto }}</td>
                  <td>{{ producto.nombre_producto }}</td>
                  <td>{{ producto.cantidad }}</td>
                  <td>{{ producto.unidad }}</td>
                  <td>{{ formatDate(producto.fecha) }}</td>
                  <td>{{ producto.comentario }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="mostrarModal = false"
            >Cerrar</b-button
          >
          <b-button
            v-if="rolModuloPermiso.escritura"
            type="submit"
            variant="success"
            class="ms-1"
            @click="guardarCerrar = false"
            >Guardar</b-button
          >
          <b-button
            v-if="rolModuloPermiso.escritura"
            type="button"
            variant="success"
            class="ms-1"
            @click="crearSolicitud()"
            >Guardar y Cerrar</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="mostrarModalDetalle"
      title="Detalle Solicitud"
      hide-footer
      size="xl"
    >
      <div class="table-responsive table-solicitud-material-detalle">
        <table class="table mb-0 tabla-personalizada">
          <thead>
            <tr>
              <th>Proyecto</th>
              <th>Lugar Instalación</th>
              <th>Código Producto</th>
              <th>Nombre Producto</th>
              <th>Cantidad</th>
              <th>Unidad</th>
              <th>Fecha</th>
              <th>Comentario</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(detalle, i) in solicitudMaterialDetalles" :key="i">
              <td>{{ detalle.codigo_proyecto }}</td>
              <td>{{ detalle.codigo_lugar_instalacion }}</td>
              <td>
                <span class="badge bg-success font-size-13">{{
                  detalle.codigo_producto
                }}</span>
              </td>
              <td>{{ detalle.nombre_producto }}</td>
              <td>{{ detalle.cantidad }}</td>
              <td>{{ detalle.unidad }}</td>
              <td>{{ formatDate(detalle.fecha) }}</td>
              <td>{{ detalle.comentario }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    <b-modal
      v-model="mostrarModalDetalleERP"
      :title="
        `Detalle Solicitud ERP - ${
          solicitudSeleccionada.solicitud_correlativo != null
            ? `COD ${solicitudSeleccionada.solicitud_correlativo}`
            : ''
        }`
      "
      hide-footer
      size="xl"
    >
      ERP
      <SolicitudMaterialDetalleERP
        :solicitudMaterial="solicitudSeleccionada"
        :lugarInstalacion="lugarInstalacion"
        @cerrarDetalle="cerrarDetalleERP"
      />
    </b-modal>

    <b-modal
      v-model="mostrarModalSolicitudDespacho"
      :title="
        `Solicitud Despacho - ${
          solicitudSeleccionada.solicitud_correlativo != null
            ? `COD ${solicitudSeleccionada.solicitud_correlativo}`
            : ''
        }`
      "
      hide-footer
      size="xl"
    >
      DESPACHO
      <SolicitudMaterialDespacho
        :solicitudMaterial="solicitudSeleccionada"
        :lugarInstalacion="lugarInstalacion"
        @cerrarSolicitudDespacho="cerrarSolicitudDespacho"
      />
    </b-modal>
  </v-container>
</template>
<script>
//import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { productoMethods, productoComputed } from "@/state/helpers";
import {
  solicitudMaterialMethods,
  solicitudMaterialComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import moment from "moment";
moment.locale("es");
import { read, utils, writeFileXLSX } from "xlsx";
import SolicitudMaterialDetalleERP from "./solicitud-material-detalle-erp.vue";
import SolicitudMaterialDespacho from "@/components/proyectos/lugarInstalacion/LugarSolicitudMaterial.vue";

export default {
  mixins: [validationMixin],
  page: {
    title: "Solicitud Materiales",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    //Layout,
    PageHeader,
    Multiselect,
    SolicitudMaterialDetalleERP,
    SolicitudMaterialDespacho,
  },

  data() {
    return {
      mostrarModalDetalleERP: false,
      filtrando: false,
      selectedRechazada: null,
      selectedRecepcionada: null,
      selectedValeConsumo: null,
      selectedEstadoSolicitud: null,
      selectedTipoBodega: null,
      selectedFechaCreacion: null,
      selectedFechaEntrega: null,
      optionsRechazada: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      optionsRecepcionada: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      optionsValeConsumo: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      estadosSolicitud: [
        { codigo: 1, nombre: "Abierta" },
        { codigo: 2, nombre: "Cerrada" },
        { codigo: 3, nombre: "Recepcionada" },
        { codigo: 4, nombre: "Consumida" },
        // { codigo: 5, nombre: "Devuelta" },
      ],
      tiposBodega: [
        { codigo: "Central", nombre: "Central" },
        { codigo: "Movil", nombre: "Movil" },
      ],

      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      lugarInstalacion: {},
      proyectos: [],
      title: "Solicitud Materiales",
      items: [
        {
          text: "Gestion Materiales",
          active: true,
        },
        {
          text: "Solitudes Materiales",
          href: `/materiales/solicitudMaterial`,
        },
      ],
      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "fecha_creacion",
      sortDesc: true,
      fields: [
        {
          key: "Accion",
          label: "Acción",
          sortable: false,
        },
        {
          key: "solicitud_correlativo",
          label: "Código Solicitud",
          sortable: true,
        },
        {
          key: "codigo_proyecto",
          label: "Proyecto",
          sortable: true,
        },
        {
          key: "codigo_lugar_instalacion",
          label: "Lugar Instalación",
          sortable: true,
        },
        {
          key: "codigo_bodega",
          label: "Código Bodega",
          sortable: true,
        },
        {
          key: "nombre_bodega",
          sortable: true,
        },
        {
          key: "fecha_creacion",
          label: "Fecha Creación",
          sortable: true,
        },
        {
          key: "fecha_entrega",
          sortable: true,
        },
        {
          label: "Usuario que solicita",
          key: "nombre_usuario",
          sortable: true,
        },
        {
          key: "nombre_estado_solicitud",
          label: "Estado Solicitud",
          sortable: true,
        },
        {
          key: "tipo_bodega",
          sortable: true,
        },
      ],
      codigoProyecto: null,
      codigoLugarInstalacion: null,
      guardarCerrar: true,
      solicitudSeleccionada: {},
      solicitudMaterial: {
        codigo_lugar_instalacion: "",
        codigo_proyecto: "",
        productos: [],
      },
      solicitudMateriales: [],
      solicitudMaterialesDetalle: [],
      solicitudMaterialDetalles: [],
      solicitudMaterialDetallesCargando: false,
      esEditar: false,
      submitted: false,
      mostrarModal: false,
      productos: [],
      productosCargando: false,
      solicitudMaterialesCargando: false,
      unidades: [
        { value: "un", label: "un" },
        { value: "m", label: "m" },
        { value: "m3", label: "m3" },
        { value: "kg", label: "kg" },
        { value: "ton", label: "ton" },
        { value: "día", label: "día" },
      ],
      fechaValidar: null,
      fechaTerminoValidar: null,
      form: {
        producto: null,
        cantidad: null,
        fecha: null,
        comentario: null,
        unidad: null,
      },
      mostrarModalDetalle: false,
      rolModuloPermiso: {},
      filtroSolicitudes: [],
      solicitudesFiltrados: [],
      mostrarModalSolicitudDespacho: false,
    };
  },
  validations: {
    form: {
      producto: { required },
      cantidad: { required },
      fecha: { required },
      comentario: { required },
      unidad: { required },
    },
  },
  mounted() {
    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    this.lugarInstalacion = {
      codigo_lugar_instalacion: this.codigoLugarInstalacion,
      codigo_proyecto: this.codigoProyecto,
    };
    this.obtenerSolicitudMaterialesTodo();
    this.solicitudMaterial.codigo_lugar_instalacion = this.codigoLugarInstalacion;
    this.solicitudMaterial.codigo_proyecto = this.codigoProyecto;
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;
  },
  methods: {
    ...solicitudMaterialMethods,
    ...productoMethods,
    ...proyectoMethods,
    cerrarDetalleERP() {
      this.mostrarModalDetalleERP = false;
    },
    cerrarSolicitudDespacho() {
      this.mostrarModalSolicitudDespacho = false;
    },
    abrirModalDetalleERP(item) {
      this.solicitudSeleccionada = item;
      this.mostrarModalDetalleERP = true;
    },
    abrirModalSolicitudDespacho(item) {
      this.solicitudSeleccionada = item;
      this.mostrarModalSolicitudDespacho = true;
    },
    exportarExcel() {
      this.solicitudMaterialesCargando = true;
      this.excelGeneral({
        rechazada: this.selectedRechazada,
        recepcionada: this.selectedRecepcionada,
        vale_consumo: this.selectedValeConsumo,
        estado_solicitud_id:
          this.selectedEstadoSolicitud != null
            ? this.selectedEstadoSolicitud.codigo
            : null,
        tipo_bodega:
          this.selectedTipoBodega != null
            ? this.selectedTipoBodega.codigo
            : null,
        fecha_creacion: this.selectedFechaCreacion,
        fecha_entrega: this.selectedFechaEntrega,
      })
        .then((res) => {
          let data = utils.json_to_sheet(res.body);
          const workbook = utils.book_new();
          const filename = "Solicitud Materiales";
          utils.book_append_sheet(workbook, data, filename);
          writeFileXLSX(workbook, `${filename}.xlsx`);
        })
        .catch((error) => {});
    },
    limpiarRechazada() {
      this.selectedRechazada = null;
      this.filtrar();
    },
    filtrar() {
      this.filtrando = true;
      this.solicitudMaterialesCargando = true;

      if (
        this.selectedRechazada == null &&
        this.selectedRecepcionada == null &&
        this.selectedValeConsumo == null &&
        this.selectedEstadoSolicitud == null &&
        this.selectedTipoBodega == null &&
        this.selectedFechaCreacion == null &&
        this.selectedFechaEntrega == null
      ) {
        this.obtenerSolicitudMaterialesTodo();
        this.filtrando = false;
      } else {
        this.filtro({
          rechazada: this.selectedRechazada,
          recepcionada: this.selectedRecepcionada,
          vale_consumo: this.selectedValeConsumo,
          estado_solicitud_id:
            this.selectedEstadoSolicitud != null
              ? this.selectedEstadoSolicitud.codigo
              : null,
          tipo_bodega:
            this.selectedTipoBodega != null
              ? this.selectedTipoBodega.codigo
              : null,
          fecha_creacion: this.selectedFechaCreacion,
          fecha_entrega: this.selectedFechaEntrega,
        })
          .then((res) => {
            this.solicitudesFiltrados = res.body.solicitud_materiales;

            this.solicitudMaterialesCargando = false;
            this.filtrando = false;
            this.totalRows = this.solicitudesFiltrados.length;
          })
          .catch((error) => {
            this.solicitudMaterialesCargando = false;
            this.filtrando = false;
          });
      }
    },
    filtrarRechazada() {
      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.rechazada != true
      );

      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.rechazada != false
      );

      this.filtroSolicitudes.push({ rechazada: this.selectedRechazada });
      const filtro = [];
      this.filtroSolicitudes.forEach((item) => {
        filtro.push(
          ...this.solicitudesFiltrados.filter(
            (val) => val.rechazada == item.rechazada
          )
        );
      });

      this.solicitudesFiltrados = filtro;
      this.totalRows = this.solicitudesFiltrados.length;
    },
    filtrarRecepcionada() {
      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.recepcionada != true
      );

      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.recepcionada != false
      );

      this.filtroSolicitudes.push({
        recepcionada: this.selectedRecepcionada,
      });
      const filtro = [];
      this.filtroSolicitudes.forEach((item) => {
        filtro.push(
          ...this.solicitudesFiltrados.filter(
            (val) => val.recepcionada == item.recepcionada
          )
        );
      });

      this.solicitudesFiltrados = filtro;
      this.totalRows = this.solicitudesFiltrados.length;
    },
    filtrarValeConsumo() {
      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.valeConsumo != true
      );

      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.valeConsumo != false
      );
      this.filtroSolicitudes.push({
        valeConsumo: this.selectedValeConsumo,
      });
      const filtro = [];
      this.filtroSolicitudes.forEach((item) => {
        filtro.push(
          ...this.solicitudesFiltrados.filter(
            (val) => val.vale_consumo == item.codigo
          )
        );
      });

      this.solicitudesFiltrados = filtro;
      this.totalRows = this.solicitudesFiltrados.length;
    },
    filtrarEstadoSolicitud() {
      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.estado_solicitud_id == null
      );
      this.filtroSolicitudes.push({
        estado_solicitud_id: this.selectedEstadoSolicitud.codigo,
      });

      const filtro = [];
      this.filtroSolicitudes.forEach((item) => {
        filtro.push(
          ...this.solicitudesFiltrados.filter(
            (val) => val.estado_solicitud_id == item.estado_solicitud_id
          )
        );
      });

      this.solicitudesFiltrados = filtro;
      this.totalRows = this.solicitudesFiltrados.length;
    },
    filtrarTipoBodega() {
      this.filtroSolicitudes = this.filtroSolicitudes.filter(
        (val) => val.tipo_bodega == null
      );
      this.filtroSolicitudes.push({
        tipo_bodega: this.selectedTipoBodega.codigo,
      });

      const filtro = [];
      this.filtroSolicitudes.forEach((item) => {
        filtro.push(
          ...this.solicitudesFiltrados.filter(
            (val) => val.tipo_bodega == item.tipo_bodega
          )
        );
      });

      this.solicitudesFiltrados = filtro;
      this.totalRows = this.solicitudesFiltrados.length;
    },
    limpiarFiltro() {
      this.selectedRechazada = null;
      this.selectedRecepcionada = null;
      this.selectedValeConsumo = null;
      this.selectedEstadoSolicitud = null;
      this.selectedTipoBodega = null;
      this.selectedFechaCreacion = null;
      this.selectedFechaEntrega = null;

      this.solicitudesFiltrados = this.solicitudMateriales;
      this.totalRows = this.solicitudesFiltrados.length;
    },
    eliminarProducto(producto) {
      var filtrado = this.solicitudMaterial.productos.filter(
        (c) => c.codigo_producto != producto.codigo_producto
      );
      this.solicitudMaterial.productos = filtrado;
    },
    descripcionProducto(producto) {
      return `${producto.Producto} - (${producto.Stock}) - ${producto.Descripción}`;
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    abrirModalNuevo() {
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
      this.obtenerStockProductosLocal();
    },
    abrirModalEditar(datos) {
      this.solicitudSeleccionada = datos;
      this.resetForm();
      this.obtenerStockProductosLocal();

      this.$v.form.cantidad.$model = datos.depende_de;
      this.$v.form.fecha.$model = datos.fecha_inicio;
      this.$v.form.comentario.$model = datos.comentario;

      this.$v.form.unidad.$model = {
        value: datos.unidad,
        label: datos.unidad,
      };

      this.esEditar = true;
      this.submitted = false;
      this.mostrarModal = true;
    },
    abrirModalDetalle(item) {
      // this.solicitudSeleccionada = datos;
      this.obtenerSolicitudMaterialDetallesProyecto(item.solicitud_material_id);

      this.mostrarModalDetalle = true;
    },
    obtenerStockProductosLocal() {
      this.productosCargando = true;
      this.obtenerStockProductos()
        .then((res) => {
          this.productos = res.data;
          if (this.esEditar) {
            this.$v.form.producto.$model = {
              producto_id: this.solicitudSeleccionada.producto_id,
              nombre_producto: this.solicitudSeleccionada.nombre_producto,
            };
          }
          this.productosCargando = false;
        })
        .catch((error) => {
          this.productosCargando = false;
        });
    },
    obtenerSolicitudMaterialesTodo() {
      this.solicitudMaterialesCargando = true;
      this.obtenerSolicitudMateriales()
        .then((res) => {
          this.solicitudMateriales = res.body;
          this.solicitudesFiltrados = res.body;
          this.totalRows = this.solicitudesFiltrados.length;
          this.solicitudMaterialesCargando = false;
        })
        .catch((error) => {
          this.solicitudMaterialesCargando = false;
        });
    },
    obtenerSolicitudMaterialDetallesProyecto(solicitud_material_id) {
      this.solicitudMaterialesCargando = true;
      this.obtenerSolicitudMaterialDetalles(solicitud_material_id)
        .then((res) => {
          this.solicitudMaterialDetalles = res.body;
          this.solicitudMaterialDetallesCargando = false;
        })
        .catch((error) => {
          this.solicitudMaterialesCargando = false;
        });
    },
    crearSolicitud() {
      this.crearSolicitudMaterial(this.solicitudMaterial)
        .then((res) => {
          if (res.status == 201) {
            this.mensaje.variant = "success";
            this.mensaje.texto = "Solicitud  creada correctamente!!!";
            this.mostrarModal = false;
          }

          if (res.status == 208) {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "La solicitud ya existe!!!";
          }

          this.segundosMensajeActualizacion = this.segundos;
          this.obtenerSolicitudMaterialesTodo();
        })
        .catch((error) => {
          this.mensaje.variant = "danger";
          this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          // this.mostrarModal = false;
          this.segundosMensajeActualizacion = this.segundos;
        });
    },
    submit(e) {
      this.submitted = true;
      this.$v.form.$touch();

      let validarFechas = false;
      if (this.$v.form.fecha.$model == null) {
        this.fechaValidar = false;
        validarFechas = true;
      }

      if (this.$v.form.$invalid) {
        return;
      }
      if (validarFechas) {
        return;
      }
      const solicitud_material_id = this.solicitudSeleccionada
        .solicitud_material_id;
      const codigo_proyecto = this.codigoProyecto;
      const codigo_lugar_instalacion = this.codigoLugarInstalacion;
      const codigo_producto = this.$v.form.producto.$model.Producto;
      const nombre_producto = this.$v.form.producto.$model.Descripción;
      const cantidad = this.$v.form.cantidad.$model;
      const fecha = this.$v.form.fecha.$model;
      const comentario = this.$v.form.comentario.$model;
      const unidad = this.$v.form.unidad.$model.value;

      if (this.esEditar) {
        // this.actualizarSolicitudMaterial({
        //     solicitud_material_id,
        //     codigo_proyecto,
        //     codigo_lugar_instalacion,
        //     codigo_producto,
        //     nombre_producto,
        //     cantidad,
        //     fecha,
        //     comentario,
        //     unidad,
        // })
        //     .then((res) => {
        //         if (res.status == 202) {
        //             this.mensaje.variant = "danger";
        //             this.mensaje.texto =
        //                 "Ha ocurrido un error, intente nuevamente";
        //         }
        //         if (res.status == 204) {
        //             this.mensaje.variant = "success";
        //             this.mensaje.texto =
        //                 "Proyecto actualizado correctamente!!!";
        //             if (this.guardarCerrar) {
        //                 this.mostrarModal = false;
        //             }
        //         }
        //         this.segundosMensajeActualizacion = this.segundos;
        //         this.obtenerSolicitudMaterialesTodo();
        //     })
        //     .catch((error) => {
        //         this.mensaje.variant = "danger";
        //         this.mensaje.texto =
        //             "Ha ocurrido un error, intente nuevamente";
        //         this.mostrarModal = false;
        //         this.segundosMensajeActualizacion = this.segundos;
        //     });
      } else {
        let producto = {
          codigo_proyecto,
          codigo_lugar_instalacion,
          codigo_producto,
          nombre_producto,
          cantidad,
          fecha,
          comentario,
          unidad,
        };

        this.solicitudMaterial.productos.push(producto);
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.resetForm();
      }

      this.submitted = false;
    },
    resetForm() {
      this.form = {
        producto: null,
        cantidad: null,
        fecha: null,
        comentario: null,
        unidad: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    eliminarActividadProyectoLocal(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-18",
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-gris-claro",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarActividadProyecto(item.solicitud_material_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto = "Solicitud eliminada correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerSolicitudMaterialesTodo();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    colorTipoSolicitud(item) {
      if (item == "Solicitud") {
        return "bg-success";
      } else if (item == "Reserva") {
        return "bg-primary";
      } else if (item == "Recepcionada") {
        return "bg-warning";
      } else if (item == "Consumida") {
        return "bg-info";
      } else if (item == "ValeConsumo") {
        return "bg-info";
      } else if (item == "Devolucion") {
        return "bg-danger";
      } else if (item == "Central") {
        return "bg-success";
      } else if (item == "Movil") {
        return "bg-primary";
      } else if (item == "Abierta") {
        return "bg-success";
      } else if (item == "Cerrada") {
        return "bg-danger";
      }
    },
    irLugarinstalacion(proyecto) {
      this.seleccionarProyecto(proyecto[0]);
      this.$router.push({
        name: "lugaresInstalacion",
        params: { codigoProyecto: proyecto[0].codigo_proyecto },
      });
    },
    formatDate(value) {
      const duration = moment(value).format("DD/MM/yyyy");
      return `${duration}`;
    },
    formatDateTime(value) {
      const duration = moment(value).format("DD/MM/yyyy HH:mm:ss");
      return `${duration}`;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    todoFiltered(filteredItems) {
      this.todoTotalRows = filteredItems.length;
      this.todocurrentPage = 1;
    },

    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
  },
};
</script>

<style>
.crearActividadesProyecto {
  margin-right: 10px;
}

.table-solicitud-material-detalle {
  height: 300px;
}

.dropdown-item-personalizado {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

#filtrarSolicitudes input[type="radio"] {
  display: none;
}
#filtrarSolicitudes ul {
  width: 300px !important;
  /* height: 900px !important; */
}

.limpiar {
  color: #556ee6;
  margin-left: 10px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .boton-solicitud-material {
    margin-top: 10px;
  }
}
</style>
